@import '~pda-components/assets/fonts/Kanit/Kanit.css';
@import '~pda-components/assets/fonts/Sarabun/Sarabun.css';
@import '~antd/dist/antd.less';
@import 'variables.less';

html,
body,
#root {
  width: 100%;
  height: 100%;
  background-color: #fcfcfc;
}

body {
  margin: 0px;
}

[role='alert-error-boundary'] {
  .title {
    color: @color_red_error;
  }
  pre {
    font-family: Verdana;
  }
}

//Scrollbar
::-webkit-scrollbar {
  //ความกว้าง scroll
  width: 8px;
  height: 8px;
}
// :hover::-webkit-scrollbar {
//   //show scroll เมื่อ hover
//   display: block;
// }
::-webkit-scrollbar-track {
  //พื้นหลัง scroll
  background: #f0f2f5;
}
::-webkit-scrollbar-thumb {
  //แท่ง scroll
  background: #7a7a7a;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  //เมื่อ hover แท่ง scroll
  background: #111;
}
//End Scrollbar

.text-center {
  text-align: center;
}

@primary-color: #777777;@link-color: #1DA57A;@border-radius-base: 2px;